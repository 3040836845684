@keyframes fadeSlideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-div {
  animation-name: fadeSlideLeft;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
